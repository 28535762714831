import './App.css';
import './responsive.css';
import React, { useState, useEffect } from "react";

import 'aos/dist/aos.css';
import Footer from './Footer';
import Navigation from './Navigation';

export default function Privacy() {
  useEffect(() => {
    const body = document.querySelector('#root');

    body.scrollIntoView({
      behavior: 'smooth'
    }, 500)
  }, []);
  return (
    <div className="Privacy">
      <Navigation />
      <div className='privacy-homepage'>
        <div className='container'>
          <div className='privacy-head'>
            <h1> Privacy Policy</h1>
            <h2>EFFECTIVE DATE: JAN 14, 2023</h2>
            <p>
            Welcome to Mercart SaaS Technologies India Pvt LTD  ("us", "we", or "our") operates the weegetz.com website and the Weegetz mobile application (herein after referred to as the "Service"). Our Privacy Policy explains how we collect, use, disclose, and protect information that applies to our Service, and your choices about the collection and use of your information. This policy sets out how weegetz collects and uses the information that we collect about you when you use the weegetz services. This policy also explains the choices that you can make about the way that we use your information.
            </p>

            <p>
            By using the Website and/or registering yourself at weegetz you authorize us to contact you via email or phone call or SMS and offer you our services, imparting product knowledge, offer promotional offers running on the website & offers offered by the associated third parties, for which reasons, personally identifiable information may be collected. And irrespective of the fact that you have also registered yourself under DND or DNC or NCPR service, you still authorize us to give you a call from weegetz for the above-mentioned purposes till 365 days of your registration with us.
            </p>

            <h2> Definitions</h2>

            <p><strong> Personal Data:</strong>  Personal Data means data about a living individual who can be identified from those data.
</p>
            <p><strong>       Usage Data:</strong>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself.
</p>

            <p><strong>Cookies:</strong>Cookies are small pieces stored on your device <strong>(computer or mobile device)</strong>.
            </p>
            <p>Information we collect and its use</p>

            <p> We collect the following types of information about you:</p>

            <p>Information you provide us directly</p>

            <p> While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include Email Address, Phone Number, Business Name, First and Last Name, Cookies and Usage Data.</p>

            <h2> Usage Data</h2>

            <p>  We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device<strong>("Usage Data")</strong> .</p>

            <p>This Usage Data may include information such as your computer's Internet Protocol address <strong>(e.g. IP address)</strong>, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

            <p> When you access the Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of

            your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>

            <p> When using our service, we may ask you to provide us some permissions to measure key metrics across other apps and websites.</p>

            <h2> Cookies Information</h2>

            <p>  We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>

            <p> Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyse our Service.</p>

            <p> You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>

            <h2> Use of Data</h2>

            <p>Mercart SaaS Technologies India Pvt LTD uses the collected data for various purposes:
</p>

            <p> To provide and maintain our Service.</p>

            <p> To notify you about changes to our Service.</p>

            <p> To allow you to participate in interactive features of our Service when you choose to do so.</p>

            <p>To provide customer support.</p>

            <p> To gather analysis or valuable information so that we can improve our Service.</p>

            <p> To monitor the usage of our Service.</p>

            <p> To detect, prevent and address technical issues.</p>

            <p> To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.</p>

            <h2>  Retention of Data</h2>

            <p> Mercart SaaS Technologies India Pvt LTD will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.
</p>

            <p> Mercart SaaS Technologies India Pvt LTD will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
</p>

            <h2>Transfer of Data</h2>

            <p> Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>

            <p> Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>

            <p> Mercart SaaS Technologies India Pvt LTD  will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.
</p>

            <h2>Deletion of Data</h2>

            <p>You can request deletion of your Personal Data directly by deleting your account within your Account section. If you are unable to perform this action yourself, you can contact us at<strong>support@ weegetz.com</strong> </p>

            <p>Who you may choose to share your User Content with</p>

            <h2>Business Transaction</h2>

            <p>If Mercart SaaS Technologies India Pvt LTD  is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>

            <h2>Disclosure for Law Enforcement</h2>

            <p>Under certain circumstances, Mercart SaaS Technologies India Pvt LTD may be required to disclose your Content if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
</p>

            <h2> Legal Requirements</h2>

            <p> Mercart SaaS Technologies India Pvt LTD may disclose your Personal Data in the good faith belief that such action is necessary to:
</p>

            <p>To comply with a legal obligation.</p>

            <p>To protect and defend the rights or property of Mercart SaaS Technologies India Pvt LTD  .
</p>

            <p>To prevent or investigate possible wrongdoing in connection with the Service.</p>

            <p> To protect the personal safety of users of the Service or the public.</p>

            <p> To protect against legal liability.</p>

            <h2>Security of Data</h2>

            <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

            <p> You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area <strong>(EEA)</strong>.</p>

            <h2>Service Providers</h2>

            <p>  We may employ third party companies and individuals to facilitate our Service <strong>("Service Providers")</strong> , provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</p>

            <p> These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

            <h2>Analytics</h2>

            <p> We may use third-party Service Providers to monitor and analyse the use of our Service.</p>

            <p> Google Analytics: Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network. You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript <strong>(ga.js, analytics.js, and dc.js)</strong>  from sharing information with Google Analytics about visits activity.</p>

            <h2>Children's Privacy</h2>

            <p>weegetz does not knowingly collect or solicit personal information from children under the age of 18 and the Service and its content are not directed at children under the age of 18. In the event that we learn that we have collected personal information from a child under age 18 without verification of parental consent, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under 18. Please contact us at support@ weegetz.com</p>

            <h2> How long we keep your User Content</h2>

            <p>  Following termination or deactivation of your User account, weegetz may retain your profile information and User Content for a commercially reasonable time, and for as long as we have a valid purpose to do so. In particular, weegetz may retain your information for the purpose of comply with its legal and audit obligations, and for backup and archival purposes.</p>

            <p> Disclosure Text for Inclusion in Privacy Policy</p>

            <p> We may disclose to third party services certain personally identifiable information listed below:</p>

            <p>information you provide us such as name, email, mobile phone number.</p>

            <p>information we collect as you access and use our service, including device information, location and network carrier.</p>

            <p> This information is shared with third party service providers so that we can:</p>

            <p> personalize the app for you.</p>

            <p>perform behavioral analytics.</p>

            <p> Any Updates to this Privacy Policy</p>

            <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy. Please review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

            <h2>Contact Us</h2>

            <p>For any questions about this Privacy Policy, please contact us at  support@weegetz.com
            </p>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

