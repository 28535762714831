import './App.css';
import './responsive.css';
import React, { useState, useEffect } from "react";

import 'aos/dist/aos.css';
import Footer from './Footer';
import Navigation from './Navigation';

export default function Security() {
  useEffect(() => {
    const body = document.querySelector('#root');

    body.scrollIntoView({
      behavior: 'smooth'
    }, 500)
  }, []);
  return (
    <div className="Security">
      <Navigation/>
      <div className='container'>
        <div className='security-head'>
          <h1>  Master SaaS Agreement</h1>

          <p>  Mercart SaaS Technologies India Pvt LTD  (“WEEGETZ”, “WE” OR “COMPANY”) IS WILLING TO PROVIDE CERTAIN SERVICES TO YOU AS AN INDIVIDUAL, THE COMPANY, OR THE LEGAL ENTITY (REFERENCED BELOW AS “YOU” OR “YOUR” OR “CUSTOMER”) THAT EITHER REGISTERS FOR USE OF THE SUBSCRIPTION SERVICE (AS DEFINED BELOW) VIA COMPANY’S ONLINE REGISTRATION PORTAL OR ENTERS INTO A WRITTEN ORDER FORM WITH COMPANY OR SUBSCRIBES TO A PLAN OFFERED BY THE COMPANY THAT REFERENCES THIS AGREEMENT ONLY ON THE CONDITION THAT YOU ACCEPT ALL OF THE TERMS OF THIS AGREEMENT (“AGREEMENT”). READ THE TERMS AND CONDITIONS OF THIS AGREEMENT CAREFULLY BEFORE PURCHASING ANY SERVICES FROM COMPANY. THIS IS A LEGAL AND ENFORCEABLE CONTRACT BETWEEN YOU AND COMPANY. BY AGREEING TO THESE TERMS VIA COMPANY’S ONLINE REGISTRATION PORTAL OR BY ENTERING INTO A WRITTEN ORDER FORM OR SUBSCRIBING TO A PLAN OFFERED BY COMPANY WITH THAT REFERENCES THE AGREEMENT BELOW, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT.
</p>

          <p>  FOR THE SAKE OF CLARITY, IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF <strong>(AND FOR USE ON BEHALF OF)</strong> A COMPANY OR OTHER ENTITY<strong> (A "CORPORATE ENTITY")</strong>, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH CORPORATE ENTITY TO THE TERMS OF THIS AGREEMENT AND YOU ACKNOWLEDGE THAT THE TERM "YOU" OR "CUSTOMER" REFERENCED BELOW REFERS TO SUCH CORPORATE ENTITY.</p>

          <h2>   DEFINITIONS</h2>

          <p>   Affiliates means any corporation, partnership, or other entity now existing or hereafter organized that directly or indirectly controls, is controlled by or under common control with a Party. For purposes of this definition “control” means the direct possession of a majority of the outstanding voting securities of an entity.</p>

          <p>  Aggregate Data means (a) data generated by aggregating Customer Data with other data so that the results cannot be associated with Customer and/or (b) any de-identified data and learnings regarding Customer’s use of the Services.</p>

          <p>  Company Data means all data and information made available by Company to Customer through the Subscription Services, excluding Customer Data and Company’s Confidential Information.</p>

          <p>  Confidential Information has the meaning given to it in Section 4.1.</p>

          <p>  Customer means the person or entity that enters into an Order Form with Company that expressly references this Agreement or subscribes to a plan offered by Company.</p>

          <p>  Customer Data means all data and information made available by the Customer or its Users to Company or 1.6otherwise provided by Customer or its Users in connection with its and their use of the Services.</p>

          <p> Material Breach means Company suspends the performance of its obligation under this Agreement and fails to correct such breach within Ninety (90) days from the date of receipt of a notice from the Customer, provided, however, that the suspension of Services by the Company shall not be considered a breach if such suspension is on account of Customer’s breach of this Agreement or any applicable laws.</p>

          <p>  Order Form means Company ordering document either (i) signed by duly authorized representatives of both parties which references this Agreement; or (ii) filled in and accepted by Customer in connection with Company provided online registration portal, and/or which identifies the specific Services ordered by Customer from Company, sets forth the prices for the Services and contains other applicable terms and conditions.</p>

          <p> Services means the Subscription Service and any ancillary services provided by Company pursuant to Section hereof.</p>

          <p>Subscription Service means Company’s proprietary subscription-based software-as-a-service offerings set forth and described on the applicable Order Form and all updates and enhancements thereto made available by Company to Customer hereunder.</p>

          <p> Third Party Applications means online, web-based applications or services and offline software products that are provided by third parties and interoperate with the Subscription Service.</p>

          <p> Tools means algorithms, machine learning and other generally available technological tools.</p>

          <p> Users mean individuals who are authorized by the Customer to use the Services, and, with respect to the Subscription Service, who have been supplied passwords by the Customer. Users shall consist of any employee of the Customer.</p>

          <h2>SERVICES</h2>

          <p>  Services. Company shall provide the Customer with the specific Services specified on an Order Form. Any conflict between the terms and conditions set forth in this Agreement and any Order Form shall be resolved in favor of the Order Form. The Customer agrees that purchases hereunder are neither contingent on the delivery of any future functionality or features nor dependent on any oral or written comments made by Company regarding future functionality or features. In case the Customer is interested in any additional services provided by Company, then the Customer shall communicate to Company regarding the same and Company shall be entitled to charge extra for such additional services provided.</p>

          <p>  License Grant. Subject to the terms and conditions of this Agreement, and in consideration for the payment of fees set forth on the applicable Order Form, Company hereby grants to the Customer, solely during the term of the applicable Order Form, a non-exclusive, non-transferable <strong> (except as set forth in Section 10.2) </strong>license to access and use the Subscription Service solely for the Customer’s business purposes or other licenses <strong>(if any)</strong> set forth on the applicable Order Form. This license is restricted to use by Customer and its Users and does not include the right to use the Subscription Service on behalf of any third party. The Customer is responsible for procuring and maintaining all equipment and network connections necessary for the Customer to connect to the Subscription Service. The Customer agrees: a) that only authorized Users are permitted to use the Subscription Service; b) that it is responsible for authorized Users’ actions or failures to act in connection with activities contemplated under this Agreement and c) to otherwise take all commercially reasonable steps to protect the Subscription Service from unauthorized use and/or access.</p>

          <p> Third Party Web Sites, Products and Services. If Customer installs or enables Third Party Applications for use with the Subscription Service, Customer agrees that Company may allow such third-party providers to access Customer Data as required for the interoperation of such Third-Party Applications with the Subscription Service, and any exchange of data or other interaction between Customer and a third party provider is solely between Customer and such third party provider. Customer acknowledges that its ability to access and use the Third-Party Application with the Subscription Service is subject to the continued</p>

          <p> effectiveness and terms of the contract between Company and the applicable third party provider.</p>

          <h2>FEES; PAYMENT TERMS</h2>
          
          <p>Fees. The Customer agrees to pay Company for Services provided and expenses incurred in accordance with and at the rates specified in each Order Form and invoices, as applicable on a case-to-case basis. The Customer agrees to pay a late charge of one and one-half percent <strong>(1.5%)</strong> per month <strong>(or part of a month)</strong>, or the maximum lawful rate permitted by applicable law, whichever is less, for all amounts, not subject to a good faith dispute, and not paid when due. In addition to paying the applicable fees, Customer shall also pay all reasonable travel and out-of-pocket expenses incurred by Company in connection with any Services rendered provided that Customer has pre-approved of such travel and expenses in writing in advance. It is clarified that in case this Agreement is terminated due to non-compliance of terms of this Agreement on the part of Customer, or the Agreement is terminated due to breach of its term by the Customer, then Company shall be entitled to all of the Fees due under the applicable Order Form for the entire unexpired terminated portion of the term of such Order Form and/or invoice.</p>

          <p> Disputed Charges. If the Customer disputes any charge or amount on any invoice in good faith and such dispute cannot be resolved promptly through good faith discussions between the parties, the Customer shall pay the amounts due under this Agreement less the disputed amount, and the parties shall proceed in good faith to promptly resolve such disputed amount. An amount will be considered disputed in good faith only if <strong></strong>(i) the Customer delivers a written statement to Company on or before the due date of the invoice, describing in detail the basis of the dispute and the amount being withheld by the Customer,<br/> <strong></strong>(ii) such written statement represents that the amount in dispute has been determined after due investigation of the facts and that such disputed amount has been determined in good faith, and <strong></strong>(iii) all other amounts due from the Customer that are not in dispute have been paid as and when required under this Agreement.</p>

          <p> Taxes. The Customer shall be responsible for the payment of GST, state tax and similar taxes arising from or relating to the Services rendered hereunder, except for taxes related to the net income of Company and any taxes or obligations imposed upon Company under the applicable tax laws.</p>

          <h2>CONFIDENTIALITY</h2>

          <p>  Confidential Information. During the term of this Agreement, each Party will regard any information provided to it by the other Party and designated in writing as proprietary or confidential to be confidential <strong>(“Confidential Information”)</strong>. Confidential Information shall also include information which, would be understood by a reasonable person familiar with the disclosing Party’s business and the industry in which it operates, to be of a confidential or proprietary nature. The receiving Party shall not disclose <strong>(or permit its personnel to disclose)</strong> any Confidential Information to any person or entity except to a director, officer, employee, outside consultant, or advisor <strong>(collectively “Representatives”)</strong> who has a need to know such Confidential Information in the course of the performance of their duties for the receiving Party and who are bound by a duty of confidentiality no less protective of the disclosing Party’s Confidential Information than this Agreement. The receiving Party and its Representatives shall use such Confidential Information only for the purpose for which it was disclosed and shall not use or exploit such Confidential Information for its own benefit
            or the benefit of another without the prior written consent of the disclosing Party. Each Party will be responsible for the actions and omissions of its Representatives and shall protect the other Party’s Confidential Information in the same manner as it protects its own valuable confidential information, but in no event shall less than reasonable care be used. A receiving Party shall promptly notify the disclosing Party upon becoming aware of any unauthorized access or disclosure of the disclosing Party’s Confidential Information.</p>

          <p> Exclusions. Information will not be deemed Confidential Information hereunder if such information: (i) is known prior to receipt from the disclosing Party, without any obligation of confidentiality; (ii) becomes known to the receiving Party directly or indirectly from a source other than one having an obligation of confidentiality to the disclosing Party; (iii) becomes publicly known or otherwise publicly available, except through the receiving Party’s <strong>(or its Representatives’)</strong> breach of this Agreement; or (iv) is independently developed by the receiving Party without use of the disclosing Party’s Confidential Information. The receiving Party may disclose Confidential Information (a) pursuant to the requirements of applicable law, legal process, or government regulation, provided that it gives the disclosing Party reasonable prior written notice <strong>(if legally permitted)</strong> to permit the disclosing Party to contest such disclosure, and such disclosure is otherwise limited to the required disclosure; or (b) on a confidential basis as reasonably necessary to potential investors or acquirers.</p>

          <p> Injunctive Relief. Notwithstanding any other provision of this Agreement, both parties acknowledge that any use of the disclosing Party’s Confidential Information in a manner inconsistent with the provisions of this Agreement may cause the disclosing Party irreparable and immediate damage for which remedies other than injunctive relief may be inadequate. Therefore, both parties agree that, in addition to any other remedy to which the disclosing Party may be entitled hereunder, at law or equity, the disclosing party shall be entitled to seek an injunction or injunctions <strong>(without the posting of any bond and without proof of actual damages)</strong> to restrain such use in addition to other appropriate remedies available under applicable law.</p>

          <h2>WARRANTIES</h2>

          <p>  Subscription Service Warranty. Company warrants that during the term of any Order Form for the Subscription Service, or any additional services as requested by the Customer, the Subscription Service or such additional services will conform, in all material respects, and will be provided in a professional manner. Company does not warrant that it will be able to correct all reported defects or that use of the Subscription Service or such additional services will be uninterrupted or error free. Company makes no warranty regarding features or services provided by third parties. Customer’s sole and exclusive remedy for any breach of the above warranties will be for Company to, at no additional cost to Customer, provide remedial services necessary to enable the Services to conform to the warranty. The Customer will provide the Company with a reasonable opportunity to remedy any breach and reasonable assistance in remedying any defects. Such warranties shall only apply if the Services have been utilized by the Customer in accordance with the Order Form and this Agreement.</p>

          <p>
          Harmful Code. Company represents and warrants that it will not knowingly include, in any Company software released to the public and provided to Customer hereunder, any computer code or other computer instructions, devices or techniques, including without limitation those known as disabling devices, trojans, or time bombs, that intentionally disrupt, disable, harm, infect, defraud, damage, or otherwise impede in any manner, the operation of a network, computer program or computer system or any component thereof, including its security or user data. If, at any time, Company fails to comply with the warranty in this Section, Customer may promptly notify Company in writing of any such noncompliance. Company will, within thirty (30) days of receipt of such written notification, either correct the noncompliance or provide Customer with a plan for correcting the noncompliance. If the noncompliance is not corrected or if a reasonably acceptable plan for correcting them is not established during such period, Customer may terminate this Agreement for convenience as its sole and exclusive remedy for such noncompliance.
          Availability. Company will undertake commercially reasonable efforts to make the Subscription Service available twenty-four (24) hours a day, seven (7) days a week. Notwithstanding the foregoing, Company reserves the right to suspend Customer’s access to the Subscription Service: (i) for scheduled or emergency maintenance, or (ii) in the event Customer is in breach of this Agreement or any applicable law, including failure to pay any amounts due to Company.The Customer may email support@weeketz.com for all matters relating to this Agreement.

</p>

          <p> No Other Warranty. COMPANY DOES NOT REPRESENT THAT THE SERVICES WILL BE ERROR-FREE OR THAT THE SERVICES WILL MEET CUSTOMER’S REQUIREMENTS OR THAT ALL ERRORS IN THE SERVICES WILL BE CORRECTED OR THAT THE OVERALL SYSTEM THAT MAKES THE SUBSCRIPTION SERVICE AVAILABLE <strong>(INCLUDING BUT NOT LIMITED TO THE INTERNET, OTHER TRANSMISSION NETWORKS, AND CUSTOMER’S LOCAL NETWORK AND EQUIPMENT)</strong> WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE WARRANTIES STATED IN SECTION 5.1 AND SECTION 5.2 ABOVE ARE THE SOLE AND EXCLUSIVE WARRANTIES OFFERED BY COMPANY. EXCEPT FOR THE WARRANTIES STATED IN SECTION 5.1 AND SECTION 5.2, THE SERVICES ARE PROVIDED AS-IS AND THERE ARE NO OTHER WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, THOSE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS. COMPANY HEREBY DISCLAIMS ALL OTHER WARRANTIES <strong>(EXPRESS OR IMPLIED)</strong>. CUSTOMER ASSUMES ALL RESPONSIBILITY FOR DETERMINING WHETHER THE SERVICES ARE ACCURATE OR SUFFICIENT FOR CUSTOMER’S PURPOSES.</p>

          <h2>  LIMITATION OF LIABILITY.</h2>

          <p>   Consequential Damage Exclusion. Neither Party will be liable to the other or any third party for loss of profits or for any special, indirect, incidental, consequential or exemplary damages <strong>(including without limitation, damages for loss of business profits, loss of goodwill, business interruption, loss of business information and/or data)</strong> in connection with the performance of the Services, or the performance of any other obligations under this Agreement, even if it is aware of the possibility of the occurrence of such damages.</p>

          <p>  Limitation of Liability. The total cumulative liability of Company to Customer for any and all claims and damages under this Agreement, whether arising by statute, contract, tort or otherwise, will not exceed the Services fees paid by Customer to Company under the Order Form/invoice for the Services which form the subject of the claim during the six (6) month period immediately preceding the event giving rise to the claim. The provisions of this Agreement allocate risks between the parties. The pricing set forth in each Order Form reflects this allocation of risk and the limitation of liability specified herein.</p>

          <h2> TERM</h2>

          <p> Term. The term of each Order Form shall begin on the effective date of the Order Form and shall continue for length of term set forth therein. The term of this Agreement shall begin on the effective date of the first Order Form executed by the parties or date on which the customer subscribes to a plan offered by Company and will continue until (a) it is terminated in accordance with Section 7.2; or (b) there are no effective Order Forms, whichever occurs earlier. Unless otherwise expressly provided in the applicable Order Form, each Order Form for the Subscription Service shall automatically renew for additional terms of one (1) year each at such fee as decided by Company from time to time, unless either Party notifies the other Party in writing at least thirty (30) days prior to the then current expiration date that it has elected not to renew such Order Form. Company further reserves the right to change the rates, applicable charges and usage policies and to introduce new charges, for such Order Form which shall either be updated on its website or by providing the Customer with written notice thereof <strong>(which notice may be provided by e-mail)</strong> at least 30 days prior to the then current renewal date of the Order Form.</p>

          <p> Termination. Notwithstanding the foregoing, either Party may terminate this Agreement or any Order Form (i) immediately in the event of a material breach of this Agreement or any such Order Form by the other Party that is not cured within thirty (30) days of written notice from the other Party, or (ii) immediately if the other Party ceases doing business or is the subject of a voluntary or involuntary bankruptcy, insolvency or similar proceeding, that is not dismissed within ninety (90) days of filing.</p>

          <p> Company reserves the right to terminate any/all Order Forms and/or this Agreement with immediate effect, if it speculates the customer is in breach of any of its obligations under this Agreement, including but not limited to section 8.3. Company shall have the authority to immediately suspend/ terminate such customer’s account at its sole discretion and will not be liable to refund the fee in such case.</p>

          <p>Termination of an Order Form shall not be deemed a termination of any other Order Forms. Upon termination of this Agreement, all Order Forms will immediately terminate. All rights and obligations of the parties which by their nature are reasonably intended to survive such termination or expiration will survive termination or expiration of each Order Form, including, without limitation, restrictions, accrued rights to payment, confidentiality obligations, intellectual property rights, warranty disclaimers, and limitations of liability.</p>

          <p>  Effect of Termination. Upon any termination or expiration of any applicable Order Form, Company shall no longer provide the applicable Services to the Customer and the Customer shall promptly cease and cause its Users to promptly cease using the Services. The Customer shall pay Company for all fees that had accrued prior to the termination date. Except as expressly provided herein, termination of an Order Form by either party will be a nonexclusive remedy for breach and will be without prejudice to any other right or remedy of such party. If the Order Form is terminated for any reason other than a termination as a result of Company’s Material Breach, then Company shall be entitled to all of the Fees due under the applicable Order Form for the entire unexpired terminated portion of the term of such Order Form. If the Order Form is terminated as a result of Company’s Material Breach, then Customer shall be entitled to a refund of the pro rata portion of any prepaid subscription fees paid by Customer to Company under this Agreement for the remaining unexpired terminated portion of the Term. Customer agrees that following the termination or expiration, Company will delete all Customer Data in its possession.</p>

          <h2> OWNERSHIP; USE OF DATA; OBLIGATIONS</h2>

          <p>  Subscription Service. The Customer acknowledges and agrees that as between Company and the Customer, all right, title and interest in and to the Subscription Service <strong>(excluding any Customer Data)</strong> and including all modifications and configurations thereto, all Company Data and all of Company’s proprietary technology, including, without limitation, all software, products, processes, algorithms, user interfaces, know-how, techniques, designs and other tangible or intangible technical material or information made available to the Customer by Company in providing the Subscription Service and all derivatives thereof are and shall remain exclusively owned by Company or its licensors. The Company name, all Company logos, and the product names associated with the Subscription Service are trademarks of Company or third parties, and no right or license is granted to use them. During the term of this Agreement, Company grants to the Customer a limited, worldwide, non-exclusive, non-transferable <strong>(except as set forth in Section 10.2)</strong>, non-sublicensable, royalty-free right to use, display, transmit, and distribute the Company Data solely in connection with the Customer’s permitted use of the Subscription Service. Company does not guarantee the accuracy, integrity or quality of Company Data. Company shall have the right to use Customer Data to (a) generate Aggregate Data and (b) to create, develop, and enhance Tools in connection with the Software. The parties agree that the Company may use Aggregate Data and Tools for Company’s business purposes <strong>(including without limitation to develop, promote and improve its Services)</strong> during and/or after the term of this Agreement.</p>

          <p> Customer Data. The Customer retains ownership of all right, title and interest in and to all Customer Data. During the term of the Agreement, the Customer hereby grants to Company a limited, worldwide, non-exclusive, royalty-free right to use, display, transmit, and distribute the Customer Data solely as necessary to provide the Subscription Service to the Customer.</p>

          <p> Customer Obligations. The Customer is responsible for all activities conducted under its User logins and for its Users’ compliance with this Agreement. Without Company’s express prior written consent in each instance, the Customer shall not <strong>(and shall not allow any third party to)</strong>: reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code form or structure of the Subscription Service or access the Subscription Service in order to build a competitive product or service or copy any ideas, features, functions or graphics of the Subscription Service. Except as expressly permitted in this Agreement, the Customer shall not copy, license, sell, transfer, make available, lease, time-share, distribute, or assign this license, the Subscription Service to any third-party. Neither the Customer nor its Users shall use the Subscription Service to: (a) send, upload or otherwise transmit any Customer Data that is unlawful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, hateful, or racially, ethnically or otherwise objectionable; (b) upload or otherwise transmit, display or distribute any Customer Data that infringes any trademark, trade secret, copyright or other proprietary or intellectual property rights of any person; (c) upload or otherwise transmit any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; (d) interfere with or disrupt the Subscription Service or networks connected to the Subscription Service; or (e) violate any applicable law or regulation.</p>

          <h2>INDEMNIFICATION</h2>

          <p>  Company Indemnification. Subject to Section 9.3 below, Company will defend Customer, and their respective officers, directors, employees, and agents <strong>(collectively, the "Customer Indemnitees")</strong>, against any actual claim, demand, suit or proceeding arising out of or in connection with (i) gross breach of any obligation and covenant under this Agreement; (ii) violation of any applicable laws and statutory obligation while performing the Services. If a claim against customer is brought or is likely, in Company's sole opinion, to be brought, Company will, at its option (A) obtain the right for Customer to continue using the Services; (B) replace or modify the affected Services so that they becomes non-infringing; or (C) upon notice to Customer, terminate this Agreement or Customer's use of the affected Services, provided that in the case of (C) Company promptly refunds to Customer the prorated portion of any unearned pre-paid annual subscription fees paid hereunder for the affected Services.</p>

          <p> Company's obligations in this Section 9.1 do not cover third party claims to the extent such claims arise from:</p>

          <p> (1) any products, services, technology, materials or data not created or provided by Company <strong>(including without limitation any Customer Data)</strong>, (ii) any part of the Services made in whole or in part in accordance to Customer specifications, (iii) any modifications made after delivery by Company, (iv) any combination with other products, processes or materials not provided by Company <strong>(where the alleged damages, costs or expenses arise from or relate to such combination)</strong>, (v) where Customer continues allegedly infringing activity after being notified thereof or after being informed of modifications that would have avoided the alleged infringement, or (vi) Customer’s use of the Services is not strictly in accordance with this Agreement or any documentation.</p>

          <p>  Customer Indemnification. Subject to Section 9.3 below, Customer will defend Company, its Affiliates and their respective officers, directors, employees, consultants and agents <strong>(collectively, the "Company Indemnitees")</strong> against any claim, demand, suit or proceeding made or brought against any or all of the Company Indemnitees by a third party (i) alleging that the Customer Data, or any use thereof, infringes the intellectual property rights or proprietary rights of others, or has caused harm to a third party, (ii) arising out of the Customer’s breach of Sections 8.3 above, or (iii) Customer’s misuse of the Services <strong>(each, a "Claim Against Company")</strong>, and will indemnify the Company Indemnitees from any damages, reasonable attorney fees and costs finally awarded against the Company Indemnitees as a result of, or for any amounts paid under a court-approved settlement of a Claim Against Company.</p>

          <p>  Indemnification Procedure. Each Party’s obligation to indemnify the other party is conditioned on the Party seeking indemnification: (i) promptly notifying the indemnifying Party in writing of any claim, suit or proceeding for which indemnity is claimed, provided that failure to so notify will not remove the indemnifying Party’s obligation except to the extent it is prejudiced thereby, (ii) allowing the indemnifying Party to solely control the defense of any claim, suit or proceeding and all negotiations for settlement; provided that the indemnifying Party shall not settle any claim that requires the indemnified Party to admit fault without the indemnified Party’s prior written consent <strong>(such consent not to be unreasonably withheld or delayed)</strong>, and (ii) giving the indemnifying Party reasonable assistance in the defense and settlement of any claim, suit or proceeding for which indemnity is claimed.</p>

          <p>Sole Remedy. This Section 9 states the indemnifying party's sole liability to, and the indemnified party's exclusive remedy against, the other party for any type of claim described in this section.</p>

          <h2> GENERAL</h2>

          <p> Entire Agreement. This Agreement and all Order Forms contains the entire agreement between the parties with respect to the subject matter hereof, and supersedes all prior or contemporaneous proposals, understandings, representations, warranties, covenants, and any other communications <strong>(whether written or oral)</strong> between the parties relating thereto and is binding upon the parties and their permitted successors and assigns. Only a written instrument that refers to this Agreement or the applicable Order Form and that is duly signed by the authorized representatives of both parties may amend this Agreement or such Order Form. Any inconsistent or conflicting terms and conditions contained in any purchase order or similar ordering document issued by the Customer shall be of no force or effect, even if the order is accepted by the Company.</p>

          <p> Assignment. This Agreement shall be binding upon and for the benefit of Company, the Customer and their permitted successors and assigns. Either Party may assign this Agreement and all Order Forms as part of a corporate reorganization, consolidation, merger, or sale of all or substantially all of its assets. Except as expressly stated in this Agreement, neither Party may otherwise assign its rights or delegate its duties under this Agreement either in whole or in part without the prior written consent of the other Party, and any attempted assignment or delegation without such consent will be void. Company may use independent contractors or subcontractors to assist in the delivery of Services,</p>

          <p></p>
          Governing Law. This Agreement shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions. The Courts of Bengaluru shall have exclusive jurisdiction over disputes arising here from or in relation to this Agreement.
          <p> Disputes. Any disputes between the parties arising out of this Agreement shall be resolved as follows: Members of the senior management of both parties shall meet to attempt to resolve such disputes. If a dispute cannot be resolved within thirty (30) days, either party may make a written demand for mediation. Within thirty (30) days after such written notification, the parties shall meet for one day with an impartial mediator. The costs and expenses of the mediator shall be shared equally by the parties. If the dispute is not resolved by mediation, such dispute shall be referred to arbitration according to the then applicable rules under the Arbitration and Conciliation Act, 1996 <strong>(the “Act”) </strong>read with its amendments thereto. The arbitral tribunal shall consist of a sole arbitrator to be appointed by Company. The place of the arbitration shall be at Bangalore, India and the language of the arbitration shall be English, and the arbitral proceedings shall be subject to the Act. The arbitration award of the arbitrator shall be final and binding on the parties and shall be enforceable in accordance with its terms. The costs of arbitration and the manner of bearing such costs shall be shared equally by the parties. Subject to the foregoing, the parties submit to the courts in Bangalore having jurisdiction thereof.</p>

          <p>  Headings. The headings to the sections of this Agreement are for ease of reference only and shall not affect the interpretation or construction of this Agreement.</p>

          <p>  Relationship of the Parties. Company and the Customer are independent contractors, and nothing in this Agreement shall be construed as making them partners or creating the relationships of employer and employee, master and servant, or principal and agent between them, for any purpose whatsoever. Neither Party shall make any contracts,</p>

          <p> warranties or representations or assume or create any obligations, express or implied, in the other Party’s name or on its behalf.</p>

          <p>Force Majeure. Except for the obligation to make payments, nonperformance of either Party shall be excused to the extent that performance is rendered impossible by strike, fire, flood, pandemic, work stoppages,10.7accidents, acts of war or terrorism, civil or military disturbances, nuclear or natural catastrophes, governmental acts or orders or restrictions, failure of suppliers, act of god or any other reason where failure to perform is beyond the reasonable control of the non-performing Party <strong>(a “Force Majeure Event”)</strong>.</p>

          <p> Notices. Any notice, approval, request, authorization, direction or other communication under this Agreement shall be given in writing and shall be deemed to have been delivered and given for all purposes (i) if it is delivered by email;(ii) on the delivery date if delivered personally to the Party to whom the same is directed; (iii) one (1) business day after deposit with a nationally recognized overnight carrier, with written verification of receipt, or (iv) five (5) business days after the mailing date whether or not actually received, if sent by India certified mail, return receipt requested, postage and charges pre-paid or any other means of rapid mail delivery for which a receipt is available, to the address of the Party set forth on the applicable Order Form. Either Party may change its address by giving written notice of such change to the other Party.</p>

          <p>Modifications to Subscription Service. Company may make modifications to the Subscription Service or particular components of the Subscription Service from time to time provided that such modifications do not materially degrade any functionality or features of the Subscription Service.</p>

          <p>         Publicity. Unless otherwise set forth in an Order Form, Customer hereby grants Company a non-exclusive license to list Customer’s name and display the Customer’s logo in the customer section of Company’s website and to use Customer’s name and logo in Company’s customer lists.. Any other use by the Company of the Customer’s name, logo or trademark requires the Customer’s prior written consent <strong>(such consent not to be unreasonably withheld)</strong>.</p>

          <p>No Third Party Beneficiaries. Nothing contained in this Agreement is intended or shall be construed to confer upon any person any rights, benefits or remedies of any kind or character whatsoever, or to create any obligation of a Party to any such person.</p>

          <p>Waiver and Severability. Performance of any obligation required by a Party hereunder may be waived only by a written waiver signed by an authorized representative of the other Party, which waiver shall be effective only with respect to the specific obligation described therein. The failure of either Party to exercise any of its rights under this Agreement will not be deemed a waiver or forfeiture of such rights. The invalidity or unenforceability of one or more provisions of this Agreement will not affect the validity or enforceability of any of the other provisions hereof, and any invalid or unenforceable provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable</p>

        </div>
      </div>
      <Footer />
    </div>

  );
}

