import "./App.css";
import "./responsive.css";
import React, { useState, useEffect, useRef } from "react";
import "aos/dist/aos.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";

import Homepagelogo from "./images/Group 18.png";

// import Homepagelogo from "./images/Group 18.png"
import Group4318 from "./images/New folder (2)/4318.png";
import Group5318 from "./images/New folder (2)/5318.png";
import Group5319 from "./images/New folder (2)/5319.png";
import Group5320 from "./images/New folder (2)/5320.png";
import mobileicon from "./images/mobileicon.png";
import Group1 from "./images/Group 1.png";
import Group5 from "./images/Group 5.png";
import Group3 from "./images/Group 3.png";
import Group4 from "./images/Group 4.png";
import Group3336 from "./images/value img png/Group 9139.png";
import Group3337 from "./images/value img png/Group 9141-2.png";
import Group3338 from "./images/value img png/Group 9140.png";
import Group3339 from "./images/value img png/Group 9141-1.png";
import Group3340 from "./images/value img png/Group 9141.png";
import Group3341 from "./images/value img png/Group 9141-3.png";
import Group3374 from "./images/Group 3374.png";
import Group3430 from "./images/Group 3430.png";
import Group3458 from "./images/Group 3458.png";
import Group3554 from "./images/Group 3554.png";
import Group4319 from "./images/Group 4319.png";
import Group4320 from "./images/Group 4320.png";
import Group4321 from "./images/Group 4321.png";
import Group4330 from "./images/Group 4330.png";
import Group6 from "./images/Mask Group 6.png";
import WebBulk from "./images/Web/Web/Bulk Upload.png";
import WebDashboard from "./images/Web/Web/Dashboard.png";
import WebMultiStores from "./images/Web/Web/Multi-Stores.png";
import WebPOS from "./images/Web/Web/POS.png";
import WebProducts from "./images/Web/Web/Products.png";
import WebSubPlans from "./images/Web/Web/Sub Plans.png";
import WebWallet from "./images/Web/Web/Wallet.png";
import MobileCart from "./images/Mobile/Mobile/Cart.jpeg";
import MobileDashboard from "./images/Mobile/Mobile/Dashboard.jpeg";
import MobileInvoice from "./images/Mobile/Mobile/Invoice.jpeg";
import MobileMultiStores from "./images/Mobile/Mobile/Multi-Stores.jpeg";
import MobilePOS from "./images/Mobile/Mobile/POS.jpeg";
import MobileProducts from "./images/Mobile/Mobile/Products.jpeg";
import Tick from "./images/tick.png";
import whitetick from "./images/whitetick.png";
import Wrong from "./images/wrong.png";
import Navigation from "./Navigation";
import Footer from "./Footer";
import CustomModal from "./CustomModal";
import axios from "axios";
function Home() {
  const [validation, setValidation] = useState({});
  const [registerData, setRegisterData] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [validated, setValidated] = useState();
  const setRegData = (e, key) => {
    setRegisterData({ ...registerData, [key]: e });
  };
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    if (!registerData.username) {
      validdateData.username = "Fullname is required!";
      Status = false;
    }
    if (!registerData.companyname) {
      validdateData.companyname = "Company Name is required!";
      Status = false;
    }
    if (!registerData.phonenumber) {
      validdateData.phonenumber = "Phone Number is required!";
      Status = false;
    } else if (registerData.phonenumber.length !== 10) {
      validdateData.phonenumber = "Phone Number must be a 10-digit number";
      Status = false;
    }
    if (!registerData.role) {
      validdateData.role = "Role is required!";
      Status = false;
    }
    if (!registerData.email) {
      validdateData.email = "E-mail is required!";
      Status = false;
    } else if (!regex.test(registerData.email)) {
      validdateData.email = "This is not a valid email format!";
      Status = false;
    }
    if (!registerData.message) {
      validdateData.message = "Message is required!";
      Status = false;
    }

    setValidation({ ...validation, ...validdateData });
    setValidated(Status);
    if (Status === true) {
      setTimeout(() => {
        saveForm();
      }, 1000);
    }
    return Status;
  };
  const ValueValidation = (key) => {
    if (validation[key]) delete validation[key];
  };

  const personalInfoSubmit = async (e) => {
    e.preventDefault();
    const CheckData = {
      username: registerData.username,
      companyname: registerData.companyname,
      phonenumber: registerData.phonenumber,
      role: registerData.role,
      email: registerData.email,
      message: registerData.message,
    };
    if (CheckValidation()) {
      setRegisterData(CheckData);
      e.target.reset();
      
    }
  };
  const saveForm = () => {
    let Payload = {
      username: registerData.username,
      companyname: registerData.companyname,
      phonenumber: registerData.phonenumber,
      role: registerData.role,
      email: registerData.email,
      message: registerData.message,
    };
    axios
      .post("https://weegetz.com/api/contact-us.php", Payload)
      .then((response) => {
        setSuccessModal(true);
        setRegisterData({});
        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <div className="Home" id="Home">
      <Navigation />
      <div>
        {/* Online Store Start*/}
        <div className="online-store">
          <div className="container">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-7 col-md-7 col-sm-12 reveal fade-left"
                  data-aos="fade-right"
                >
                  <div className="content">
                    <h6>Get online!</h6>
                    <h1>
                      The simplest way to transform your store into an online
                      e-store in matter of few clicks.
                    </h1>

                    <h5>Enjoy your free trial today!!!​</h5>
                  </div>
                  <div className="store" data-aos="fade-up">
                    <a href="" target="_blank">
                      <img src={Group1} alt="" />
                      <span>Google Play</span>
                    </a>
                    <a href="" target="_blank">
                      <img src={Group5} alt="" />
                      <span className="app-stores">App Store</span>
                    </a>
                    <a href="" target="_blank">
                      <img src={Group3} alt="" />
                      <span>For Web</span>
                    </a>
                  </div>
                </div>
                <div
                  className="col-lg-5 col-md-5 col-sm-12 play"
                  data-aos="fade-left"
                >
                  <div className="shoping-trolly">
                    <img src={Group4318} alt="" />
                    <div>
                      {/* <video
                    style={{ maxWidth: "100%", width: "800px", margin: "0 auto" }}
                    playsInline
                    loop
                    muted
                    controls
                    alt="All the devices"
                    src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                    ref={videoEl}
                  /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Online Store Start*/}
        {/* Weegetz-Store Start*/}
        <div className="weegetz-store">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-5 col-md-5 col-sm-12 wee-store"
                data-aos="fade-left"
              >
                <div className="left-content">
                  <h1>
                    <span>Weegetz</span> Store
                  </h1>
                  <p className="head-2">A whole new e-shopping world</p>
                  <p className="head-1">
                    Weegetz is an all-in-one platform that you can create,
                    manage and grow your retail business.
                    <ul>
                      <li>Sell your products easy and cost effective</li>
                      <li>Reach more customers and boost your sale/revenue</li>
                      <li>Expand your brand value</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div
                className="col-lg-7 col-md-7 col-sm-12 wee-store"
                data-aos="fade-right"
              >
                <div className="right-img">
                  <img src={Group3336} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Weegetz-Store Start*/}
        {/* Value Start */}
        <div className="value">
          <h1 data-aos="fade-up">
            <strong>Values</strong>
          </h1>
          <h6 data-aos="fade-down">
            Running a business is no more complicated.
          </h6>
          <div class="container-fluid">
            <div class="rowed">
              <div class="card">
                <div class="content">
                  <div class="front">
                    <img src={Group3337} alt="" />
                    <h2>
                      Increase The Sales
                      <br />
                      by 15% to 20 %
                    </h2>
                  </div>
                  <div class="back from-left">
                    <p class="des">
                      Increase your revenue by bringing your local store online
                      to improve sales/revenue​.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="content">
                  <div class="front">
                    <img src={Group3338} alt="" />
                    <h2>
                      Round-The-Clock
                      <br /> Sales
                    </h2>
                  </div>
                  <div class="back from-left">
                    <p class="des">
                      Sell your products on Weegetz while you are asleep. Online
                      e-commerce stores are accessible 24/7.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="content">
                  <div class="front">
                    <img src={Group3339} alt="" />
                    <h2>No more boundary</h2>
                  </div>
                  <div class="back from-left">
                    <p class="des">
                      Customers of Weegetz store can access it from any location
                      that has internet connectivity, which allows merchants
                      access to a large global market.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="content">
                  <div class="front">
                    <img src={Group3340} alt="" />

                    <h2> Business with Weegetz is simple</h2>
                  </div>
                  <div class="back from-left">
                    <p class="des">
                      With a detailed dashboard to manage everything from
                      inventory to orders, Weegetz has made opening a online
                      store as simple as possible.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="content">
                  <div class="front">
                    <img src={Group3341} alt="" />

                    <h2>
                      We're about more than
                      <br /> just software
                    </h2>
                  </div>
                  <div class="back from-left">
                    <p class="des">
                      Weegetz offers the software to start your own store as
                      well as the business analytics and insights that can help
                      you succeed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Value End */}
        {/* Features Start */}
        <div className="features" id="Features">
          <div className="head-features">
            <h1 data-aos="fade-down">Features</h1>
            <h3 data-aos="fade-up">Weegetz Store</h3>
            <div className="container background-line-1">
              <div className="row">
                <div
                  className="col-lg-6 col-md-6 col-sm-12 features-img-1"
                  data-aos="fade-right"
                >
                  <div className="left-img">
                    <img src={Group3374} alt="" />
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12"
                  data-aos="fade-left"
                >
                  <div className="right-content">
                    <h1 className="heading">
                      Customer Intelligence Platform(CIP)
                    </h1>
                    {/* <br /><span className="bottom-heading bottom-heading-12">-Know your customer​</span> */}
                    <p>
                      CIP helps to know your customer and enhance customer's
                      experience.
                    </p>
                    <ul>
                      <li>
                        {/* <span>Customer segmentation:</span>  */}
                        Grouping up on demographic data, purchase history, and
                        search patterns.
                      </li>
                      <li>
                        {/* <span>Customer profiling:</span>  */}
                        Creating comprehensive profiles of each consumer.
                      </li>
                      <li>
                        {/* <span>Personalization:</span>   */}
                        Delivers customized information and suggestions.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12 feature-img-1 wee-store"
                  data-aos="fade-right"
                >
                  <div className="left-img">
                    <img src={Group3374} alt="" />
                  </div>
                </div>
              </div>

              {/* Predictive-Analytics */}
              <div className="row Predictive-Analytics">
                <div
                  className="col-lg-6 col-md-6 col-sm-12"
                  data-aos="fade-right"
                >
                  <div className="left-text">
                    <h1 className="heading">Predictive Analytics</h1>
                    {/* <br /><span className="bottom-heading-1 ">-Forecast/future trend​​</span> */}
                    <p className="heading-para">
                      Retailers can forecast the future trends with the data.
                      Identifies the pattern and understands how it can impact
                      the future business.
                    </p>
                    <ul>
                      <li>Provides valuable statistics and what's next.​</li>
                      <li>Estimate sales and manage inventory.</li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12 wee-store"
                  data-aos="fade-left"
                >
                  <div className="right-img">
                    <img src={Group3430} alt="" />
                  </div>
                </div>
              </div>
              {/* Multi-store-integration */}
              <div className="row Multi-store-integration">
                <div
                  className="col-lg-6 col-md-6 col-sm-12 features-img-2 "
                  data-aos="fade-right"
                >
                  <div className="right-img">
                    <img src={Group3458} alt="" />
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12"
                  data-aos="fade-left"
                >
                  <div className="left-text">
                    <h1 className="heading">Multi-store Integration</h1>
                    {/* <br /><span className="bottom-heading bottom-heading-13">- Centralized Management​​​</span> */}
                    <p className="heading-para">
                      Multi-store integration enables merchants to manage
                      multiple online stores using a single platform.
                    </p>
                    <ul>
                      <li>Centralized management and shared data​</li>
                      <li>Customized store​​</li>
                      <li>Product synchronization ​​</li>
                      <li>
                        Order management and integration with other tools.​​
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12 feature-img-2"
                  data-aos="fade-right"
                >
                  <div className="right-img">
                    <img src={Group3458} alt="" />
                  </div>
                </div>
              </div>
              {/*Customer-loyalty-programs*/}
              <div className="row Customer-loyalty-programs">
                <div
                  className="col-lg-6 col-md-6 col-sm-12"
                  data-aos="fade-right"
                >
                  <div className="left-text">
                    <h1 className="heading">Customer Loyalty Programs</h1>
                    {/* <br /><span className="bottom-heading-1 bottom-heading-12">-Rewards</span> */}
                    <p className="heading-para">
                      Rewards the customers for their repetitive business and it
                      is designed to encourage customer loyalty.
                    </p>
                    <ul>
                      <li>
                        It includes rewards points, tier levels, exclusive
                        offers, corporate benefits and personalized
                        recommendations​
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12 wee-store"
                  data-aos="fade-left"
                >
                  <div className="right-img">
                    <img src={Group3554} alt="" />
                  </div>
                </div>
              </div>
              {/* Customer-loyalty-programs End */}
              {/*Customer-loyalty-programs*/}
              <div className="row Customer-loyalty-programs">
                <div
                  className="col-lg-6 col-md-6 col-sm-12 wee-store features-img-3"
                  data-aos="fade-left"
                >
                  <div className="right-img">
                    <img src={Group5319} alt="" />
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12"
                  data-aos="fade-right"
                >
                  <div className="left-text">
                    <h1 className="heading">Just a QR code</h1>
                    {/* <br /><span className="bottom-heading-1 bottom-heading-15">-Skip the queue</span> */}
                    {/* <p className="heading-para">No more queues</p> */}
                    <p className="heading-para">
                      QR (Quick Response) codes are two-dimensional barcodes
                      that can be scanned with a smartphone or other device to
                      retrieve information or perform a task.
                    </p>
                    <ul>
                      {/* <li><span>Inventory management:</span>  Retailers can track inventory levels and manage stock using QR codes.  ​</li>
                      <li><span>Mobile payment:</span>  To pay using their mobile wallet, customers can scan a QR code at the point of sale.​</li>
                      <li><span>Product information:</span>  Customers can scan a QR code on a product to access additional information, such as product details or user reviews ​</li>
                      <li><span>Contactless payment:</span>  QR codes can be used to allow contactless payment and limit the risk of disease transmission.​</li> */}
                      <li>
                        It's used for mobile payment, product information,
                        marketing campaigns, customer loyalty programs,
                        contactless payment, in-store navigation, customer
                        feedback, and inventory management.​
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12 wee-store feature-img-3"
                  data-aos="fade-left"
                >
                  <div className="right-img">
                    <img src={Group5319} alt="" />
                  </div>
                </div>
              </div>
              {/* Customer-loyalty-programs End */}
            </div>
          </div>
          <div className="Customers">
            <div className="container background-line-2">
              {/* <div className="Shop-and-Earn">
                <div className="row ">
                  <div
                    className="col-lg-6 col-md-6 col-sm-12"
                    data-aos="fade-right"
                  >
                    <div className="left-text">
                      <h1 className="heading">Shop and Earn</h1>
                      <p className="heading-para">
                        Customers can earn rewards or points by using Weegetz shop and earn feature. These bonus points can be exchanged for saving on goods , discounts or other benefits.
                      </p>
                      <ul>
                        <li><span>Reward Points:</span>  Customers gain points with each purchase which can be redeemed  for discounts or other incentives. ​</li>
                        <li><span>Tier levels:</span>  Customers can progress through various tier levels based on their spending, with each level providing increasingly larger rewards or perks.​</li>
                        <li><span>Referral program:</span>  Customers can earn rewards by referring friends or family to the weegetz store.​</li>
                        <li><span>Exclusive offers:</span>  Customers that participate in a shop and earn program could get access to the exclusive discounts or deals that are not available to other customers​</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 wee-store"
                    data-aos="fade-left"
                  >
                    <div className="right-img">
                      <img src={Group4319} />
                    </div>
                  </div>
                </div>
              </div> */}
              {/*  */}
              <div className="row Grab-and-Go">
                <div
                  className="col-lg-6 col-md-6 col-sm-12 wee-store feature-img-4"
                  data-aos="fade-right"
                >
                  <div className="right-img">
                    <img src={Group4320} alt="" />
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12"
                  data-aos="fade-left"
                >
                  <div className="left-text">
                    <h1 className="heading">Grab and Go</h1>
                    <p className="heading-para">
                      Pick & GO futured first AI store. Order from anywhere and
                      anytime, pickup on the way without waiting.
                    </p>
                    <ul>
                      <li>Order at leisure​</li>
                      <li>Pickup on the go​</li>
                      <li>Skip queue and waiting</li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12 wee-store features-img-4"
                  data-aos="fade-right"
                >
                  <div className="right-img">
                    <img src={Group4320} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Features Start */}

        {/* Additional Features Start*/}
        <div className="Additional-Features">
          <h2 data-aos="fade-up">Weegetz POS</h2>
          <div className="container background-line-3">
            <div className="row Integrated Merchant">
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-right"
              >
                <div className="left-text">
                  <h1 className="heading">
                    Integrated Merchant and POS system
                  </h1>
                  <p className="heading-para">
                    Weegetz POS software combines the features of a merchant
                    account with a POS system, especially for businesses that
                    need to handle credit card payments as well as manage sales,
                    inventory, and customer data in a single platform.
                  </p>
                  <ul>
                    {/* <li><span>Credit card processing:</span>  The ability to accept and process credit card payments in-store or online.
                    </li>
                    <li><span>Payment gateway:</span>  A device that enables the merchant account to securely receive payment information from the POS system for processing.​</li>
                    <li><span>Sales tracking:</span>  Tracking includes creating reports on data and trends in sales as well as managing sales.
                    </li>
                    <li><span>Inventory management:</span>  The ability to track and manage inventory levels, including receiving new goods, tracking sales, and generating alerts for low stock levels.​</li>
                    <li><span>Customer data:</span>  The ability to store and access specific customer information, such as contact information, purchase history, and loyalty program data.
                    </li> */}
                    <li>Secured Payment​</li>
                    <li>Customized billing and sales tracking​</li>
                    <li>Customer data​</li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 wee-store"
                data-aos="fade-left"
              >
                <div className="right-img">
                  <img src={Group4321} alt="" />
                </div>
              </div>
            </div>
            <div className="row Sales-report">
              <div
                className="col-lg-6 col-md-6 col-sm-12 features-img-5 wee-store"
                data-aos="fade-right"
              >
                <div className="right-img">
                  <img src={Group4} alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
                <div className="left-text">
                  <h1 className="heading">
                    Sales report for tracking and auditing
                  </h1>
                  <p className="heading-para">
                    A feature that allows retailers and Weegetz merchants to
                    track and analyse their sales data. Sales reports can help
                    with performance tracking, identifying trends and patterns,
                    and preparing for audits.
                  </p>
                  <ul>
                    {/* <li><span>Customization:</span>  You can personalise the report by choosing which data to include and how to display it, such as by date range, product category, or location.​</li>
                    <li><span>Sales data:</span>  Sales data, such as total revenue, sales volume, and average sale value.​</li>
                    <li><span>Product data:</span>  View product-specific data such as sales volume, revenue, and profit margin.​</li>
                    <li><span>Data comparison:</span>  Compare sales figures to previous periods or industry benchmarks.​</li>
                    <li><span>Data Export:</span>  Export sales data to other tools, such as spreadsheet software or accounting software, for further analysis or reporting.​</li>
                    <li><span>Auditing:</span>  Generate reports for auditing purposes, such as confirming sales tax compliance or reconciling accounts.
                    </li> */}
                    <li>Personalized report generation​</li>
                    <li>
                      Sales data, such as total revenue, sales volume, and
                      average sale value. ​
                    </li>
                    <li>
                      View product-specific data such as sales volume, revenue,
                      and profit margin. ​
                    </li>
                    <li>
                      Compare sales figures to previous periods or industry
                      benchmarks.
                    </li>
                    <li>
                      Generate reports for auditing purposes, such as confirming
                      sales tax compliance or reconciling accounts.​
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 feature-img-5 wee-store"
                data-aos="fade-right"
              >
                <div className="right-img">
                  <img src={Group4} alt="" />
                </div>
              </div>
            </div>
            <div className="row More-informative">
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-right"
              >
                <div className="left-text">
                  <h1 className="heading ">
                    More informative Visual Dashboards
                  </h1>
                  <p className="heading-para">
                    Weegetz visual dashboard tool that allows e-commerce
                    merchants to view and analyse data visually, such as charts,
                    graphs, and maps.
                  </p>
                  <ul>
                    {/* <li><span>Data visualization:</span>  entails viewing data in various visual formats, such as bar charts, line graphs, and pie charts, to gain insights and identify trends and patterns.​</li>
                    <li><span>Customization:</span>  Customize the dashboard by selecting which data to display and how it is displayed, in order to focus on the most relevant information.​</li>
                    <li><span>Real-time data:</span>  View data in real-time, allowing merchants to stay current on trends and make informed decisions.​</li>
                    <li><span>Multiple data sources:</span>  Combine data from various sources, such as sales, customer, and marketing data, to gain a comprehensive view of the business.​</li>
                    <li><span>Alerts and notification:</span>  Set up alerts and notifications for specific events or thresholds, such as a sudden drop in sales or an increase in customer complaints.​</li> */}
                    <li>Data visualization​</li>
                    <li>Customization​</li>
                    <li>Real-time data​</li>
                    <li>Multiple data sources​</li>
                    <li>Alerts and notification​</li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 wee-store"
                data-aos="fade-left"
              >
                <div className="right-img">
                  <img src={Group4330} alt="" />
                </div>
              </div>
            </div>
            <div className="row More-informative">
              <div
                className="col-lg-6 col-md-6 col-sm-12 features-img-6 wee-store"
                data-aos="fade-left"
              >
                <div className="right-img-second-final">
                  <img src={Group5318} alt="" />
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-right"
              >
                <div className="left-text">
                  <h1 className="heading ">Tax Billing & Inventory</h1>
                  <p className="heading-para">
                    Weegetz POS system create invoice with Tax comply with law
                    of your country. Tax invoice and bills can be generated in
                    online and offline mode are accounted and updated in the
                    book.
                  </p>
                  <ul>
                    {/* <li><span>Tax calculation:</span>  The ability to calculate and apply the appropriate taxes to each sale based on the customer's location and the type of product or service sold.​</li>
                    <li><span>Tax exemptions:</span>  The ability to handle tax exemptions for charitable organisations or specific types of products and apply the appropriate tax rate.​</li>
                    <li><span>Multiple tax rates:</span>  The ability to manage multiple tax rates for various types of products or locations</li>
                    <li><span>Tax reporting:</span>  The ability to generate reports on collected taxes that can be used to tax reporting.​</li>
                    <li><span>Inventory tracking:</span>  The ability to track and manage inventory levels, which includes receiving new products, tracking sales, and issuing alerts when stock levels are low.
                    </li> */}
                    <li>Generate invoices​</li>
                    <li>Tax calculation ​</li>
                    <li>Tax exemptions definitions​</li>
                    <li>Multiple tax rates for products​</li>
                    <li>Tax reports​</li>
                    <li>Inventory tracking​</li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 feature-img-6 wee-store"
                data-aos="fade-left"
              >
                <div className="right-img-second-final">
                  <img src={Group5318} alt="" />
                </div>
              </div>
            </div>
            <div className="row More-informative">
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-right"
              >
                <div className="left-text">
                  <h1 className="heading ">Offline Billing</h1>
                  <p className="heading-para">
                    Continue selling without any delay and whether online or
                    offline.
                  </p>
                  <p>
                    Multiple payment options: Weegetz stores can provide
                    multiple offline payment methods acceptance, such as phone,
                    mail, and in-person, to customers who are unable to pay
                    online.​
                  </p>
                  {/* <ul>
                    <li><span>Payment by phone:</span>  Customers can make a payment by providing their payment information over a phone call.​</li>
                    <li><span>Payment by mail:</span>  Customers can mail a check or money order to the Weegetz store to make a payment.​</li>
                    <li><span>Payment in person:</span>  Customers can pay in person at a physical location like a retail store or a payment centre.​</li>
                    <li><span>Invoice billing:</span>  Customers can request an invoice and pay at a later time.​</li>
                    <li><span>Automatic billing:</span>  Customers can permit the e-commerce store to charge their payment method on a regular basis, such as monthly or quarterly.​</li>
                    <li><span>Multiple payment option:</span>  Weegetz stores can provide multiple offline payment methods, such as phone, mail, and in-person, to customers who are unable to pay online.​</li>
                  </ul> */}
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 wee-store"
                data-aos="fade-left"
              >
                <div className="right-img-final">
                  <img src={Group5320} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Additional Features End */}

        {/* WEB View Start */}
        <div className="web-view">
          <h1 data-aos="fade-up">WEB View</h1>
          <h6 data-aos="fade-down">E-commerce platform view Pos system view</h6>
          <div className="container">
            <div className="lap-view" data-aos="fade-up">
              <div
                id="carouselExampleControls"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      className="d-block w-100"
                      src={WebBulk}
                      alt="First slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={WebDashboard}
                      alt="Second slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={WebMultiStores}
                      alt="Third slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={WebPOS}
                      alt="Third slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={WebProducts}
                      alt="Third slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={WebSubPlans}
                      alt="Third slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={WebWallet}
                      alt="Third slide"
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* WEB View End*/}

        {/* APP View Start*/}
        <div className="app-view" id="AppView">
          <h1 data-aos="fade-up">APP View</h1>
          <h6 data-aos="fade-down">Ecommerce platform view Pos system view</h6>
          <div className="container">
            <OwlCarousel
              className="owl-theme"
              responsive={{
                0: {
                  items: "1",
                },
                576: {
                  items: "2",
                },
                768: {
                  items: "2",
                },
                1000: {
                  items: "3",
                },
              }}
              autoPlay={true}
              autoplayHoverPause={true}
              nav={true}
              center
              dots={false}
              autoplaySpeed={1000}
              loop
            >
              <div className="item">
                <div>
                  <div className="mobile-view-1" data-aos="fade-left">
                    <div className="image-wrap">
                      <a>
                        <img
                          src={MobileCart}
                          className="card-img-top"
                          alt="..."
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div>
                  <div className="mobile-view-1" data-aos="fade-up">
                    <div className="image-wrap">
                      <a>
                        <img
                          src={MobileDashboard}
                          className="card-img-top"
                          alt="..."
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div>
                  <div className="mobile-view-1" data-aos="fade-right">
                    <div className="image-wrap">
                      <a>
                        <img
                          src={MobileInvoice}
                          className="card-img-top"
                          alt="..."
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div>
                  <div className="mobile-view-1" data-aos="fade-left">
                    <div className="image-wrap">
                      <a>
                        <img
                          src={MobileMultiStores}
                          className="card-img-top"
                          alt="..."
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div>
                  <div className="mobile-view-1" data-aos="fade-up">
                    <div className="image-wrap">
                      <a>
                        <img
                          src={MobilePOS}
                          className="card-img-top"
                          alt="..."
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div>
                  <div className="mobile-view-1" data-aos="fade-right">
                    <div className="image-wrap">
                      <a>
                        <img
                          src={MobileProducts}
                          className="card-img-top"
                          alt="..."
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div>
                  <div className="mobile-view-1" data-aos="fade-left">
                    <div className="image-wrap">
                      <a>
                        <img
                          src={MobileCart}
                          className="card-img-top"
                          alt="..."
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
        {/* APP View End*/}
        {/* Pricing Start */}
        <div className="pricing" id="Pricing">
          <h1 data-aos="fade-down">Pricing</h1>
          <div className="container">
            <div className="seprate">
              <div className="row">
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="fade-left"
                  id="free"
                >
                  <div className="price-card">
                    <button className="button-1">FREE</button>
                    {/* <p>
                      For high performing teams who are
                      <br /> looking to break the norms
                    </p> */}
                    <h1>
                      FREE <br />
                      <span>/Monthly </span>
                    </h1>

                    <div className="pricing-adjustment-1">
                      <div className="border-bottom border-1">
                        <span className="goldTick"></span>Tax Billing and
                        invoice
                      </div>
                      <div className="border-bottom border-2">
                        <span className="goldTick"></span>Track Payments
                      </div>
                      <div className="border-bottom border-3">
                        <span className="goldTick"></span>Accounting & taxes
                      </div>
                      <div className="border-bottom border-4">
                        <span className="goldTick"></span>Visual Business
                        Metrics Dashboard
                      </div>

                      <div className="border-bottom border-7">
                        <span className="goldTick"></span>POS device
                        compatabiliy
                      </div>
                      <div className="border-bottom border-5">
                        <span>
                          <img src={Wrong} alt="" />
                        </span>
                        Marketing Watch
                      </div>
                      <div className="border-bottom border-6">
                        <span>
                          <img src={Wrong} alt="" />
                        </span>
                        Analytics & insights
                      </div>
                      <div className="border-bottom border-7">
                        <span>
                          <img src={Wrong} alt="" />
                        </span>
                        Billing advertisements
                      </div>
                      <div className="border-bottom border-7">
                        <span>
                          <img src={Wrong} alt="" />
                        </span>
                        Store QR
                      </div>
                      <div className="borders-bottom border-8">
                        <span>
                          <img src={Wrong} alt="" />
                        </span>
                        Whatsapp Integration
                      </div>
                    </div>

                    <button className="button-2 button-4">
                      Sign Up For Free Now
                    </button>
                  </div>
                </div>

                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="fade-left"
                >
                  <div className="price-card">
                    <button className="button-1">Weegetz Gold</button>
                    {/* <p>
                      For businessess, enterprise domain
                      <br />
                      registrars and registries.
                    </p> */}
                    <h1>
                      ₹100.00<span className="offer-dolor"></span> <br />
                      <span>/Monthly </span>
                    </h1>

                    <div className="pricing-adjustment-2">
                      <div className="border-bottom border-1">
                        <span className="goldTick"></span>Tax Billing and
                        invoice
                      </div>
                      <div className="border-bottom border-2">
                        <span className="goldTick"></span>Track Payments
                      </div>
                      <div className="border-bottom border-3">
                        <span className="goldTick"></span>Accounting & taxes
                      </div>
                      <div className="border-bottom border-4">
                        <span className="goldTick"></span>Visual Business
                        Metrics Dashboard
                      </div>
                      <div className="border-bottom border-5">
                        <span className="goldTick"></span>Marketing Watch
                      </div>

                      <div className="border-bottom border-7">
                        <span className="goldTick"></span>Store QR
                      </div>
                      <div className="border-bottom border-7">
                        <span className="goldTick"></span>Whatsapp Integration
                      </div>
                      <div className="border-bottom border-7">
                        <span className="goldTick"></span>POS device
                        compatabiliy
                      </div>
                      <div className="border-bottom border-6">
                        <span>
                          <img src={Wrong} alt="" />
                        </span>
                        Analytics & insights
                      </div>
                      <div className="borders-bottom border-8">
                        <span>
                          <img src={Wrong} alt="" />
                        </span>
                        Billing advertisements
                      </div>
                    </div>
                    <button className="button-2 button-3">Contact Us</button>
                  </div>
                </div>
                <div
                  className="col-lg-4  col-md-6 col-sm-12"
                  data-aos="fade-right"
                >
                  <div className="price-card">
                    <button className="button-1">Weegetz Preminum</button>
                    {/* <p>
                      For businessess, enterprise domain
                      <br />
                      registrars and registries.
                    </p> */}
                    <h1>
                      ₹150.00 <span className="offer-dolor"></span> <br />
                      <span>/Monthly </span>
                    </h1>
                    <div className="pricing-adjustment-3">
                      <div className="border-bottom border-1">
                        <span className="goldTick"></span>Tax Billing and
                        invoice
                      </div>
                      <div className="border-bottom border-2">
                        <span className="goldTick"></span>Track Payments
                      </div>
                      <div className="border-bottom border-3">
                        <span className="goldTick"></span>Accounting & taxes
                      </div>
                      <div className="border-bottom border-4">
                        <span className="goldTick"></span>Visual Business
                        Metrics Dashboard
                      </div>
                      <div className="border-bottom border-5">
                        <span className="goldTick"></span>Marketing Watch
                      </div>
                      <div className="border-bottom border-6">
                        <span className="goldTick"></span>Analytics & insights
                      </div>
                      <div className="border-bottom border-7">
                        <span className="goldTick"></span>Billing advertisements
                      </div>
                      <div className="border-bottom border-7">
                        <span className="goldTick"></span>Store QR
                      </div>
                      <div className="border-bottom border-7">
                        <span className="goldTick"></span>Whatsapp Integration
                      </div>
                      <div className="borders-bottom border-8">
                        <span className="goldTick"></span>POS device
                        compatabiliy
                      </div>
                    </div>
                    <button className="button-2 button-3">Contact Us</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Pricing End */}
        {/* About Us Start */}
        <div className="AboutUs">
          <h1 data-aos="fade-right">About Us</h1>
          <div className="container-fluid">
            <div className="content">
              <p data-aos="fade-left">
                Mercart SaaS Technologies India Pvt LTD is a large e-commerce
                SaaS solution for merchants at all <br />
                stages of development, from small startups to mid-market
                businesses to multinational enterprises.
                <br /> We aim help merchants build, innovate, and grow their
                online businesses.
              </p>
            </div>
            <div className="icons" data-aos="fade-up">
              <a href="https://www.facebook.com/Weegetz/" target="_blank">
                <i className="fa-brands fa-facebook"></i>
              </a>
              <a href="https://www.instagram.com/weegetztech/" target="_blank">
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a href="https://twitter.com/weegetz" target="_blank">
                <i className="fa-brands fa-twitter"></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UCkCs0mD7YAp7fV_69zmUllg"
                target="_blank"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/weegetz-tech-409661259/"
                target="_blank"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
        {/* About Us End*/}
        {/* Contact Us Start */}
        <div className="Contact-Us" id="Contact">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-lg-6 col-md-6 col-sm-12 contact-left-content"
                data-aos="fade-right"
              >
                <div className="left-content">
                  <h1>Contact Us</h1>
                  <p>
                    Forms again? I know. Unfortunately, we haven't found a
                    better way. So,
                    <br /> kindly bear with us and fill up this form on the
                    right.
                  </p>
                  <p>
                    We typically respond within 24 hours of you filling up the
                    form.
                  </p>
                  <p> Telephone : +91 9790522121</p>

                  <p>Mail Us : info@weegetz.com</p>

                  <h2> Address</h2>
                  <p>
                    1A, LIC Agent Colony Road,
                    <br />
                    Sundarapuram,
                    <br />
                    Coimbatore-641024
                  </p>

                  <h2>Open Hours</h2>

                  <p>Mon-Fri: 10:00-18:00</p>
                  <p>Sat-Sun: 10:00-14:00</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
                <form onSubmit={(e) => personalInfoSubmit(e)}>
                  <div className="right-form">
                    <div className="google-inputs">
                      <div className="google-input">
                        <label htmlFor="template-input">Full Name</label>
                        <input
                          type="text"
                          onChange={(e) => {
                            setRegData(e.target.value, "username");
                            ValueValidation("username");
                          }}
                        />
                      </div>
                      {validation.username && (
                        <small className="text-danger">
                          {validation.username}
                        </small>
                      )}
                    </div>
                    <div className="google-inputs">
                      <div className="google-input">
                        <label htmlFor="template-input">Company Name</label>
                        <input
                          type="text"
                          onChange={(e) => {
                            setRegData(e.target.value, "companyname");
                            ValueValidation("companyname");
                          }}
                        />
                        {validation.companyname && (
                          <small className="text-danger">
                            {validation.companyname}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="google-inputs">
                      <div className="google-input">
                        <label htmlFor="template-input">Phone Number</label>
                        <input
                          type="number"
                          maxLength="10"
                          onChange={(e) => {
                            setRegData(
                              e.target.value.slice(0, 10),
                              "phonenumber"
                            );
                            ValueValidation("phonenumber");
                          }}
                        />
                        {validation.phonenumber && (
                          <small className="text-danger">
                            {validation.phonenumber}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="google-inputs">
                      <div className="google-input">
                        <label htmlFor="template-input">Role in Company</label>
                        <input
                          type="text"
                          onChange={(e) => {
                            setRegData(e.target.value, "role");
                            ValueValidation("role");
                          }}
                        />
                        {validation.role && (
                          <small className="text-danger">
                            {validation.role}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="google-inputs">
                      <div className="google-input">
                        <label htmlFor="template-input">E-mail ID</label>
                        <input
                          type="text"
                          onChange={(e) => {
                            setRegData(e.target.value, "email");
                            ValueValidation("email");
                          }}
                        />
                        {validation.email && (
                          <small className="text-danger">
                            {validation.email}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="google-inputs">
                      <div className="google-input">
                        <label htmlFor="template-input">Message</label>
                        <textarea
                          rows="8"
                          cols="44"
                          onChange={(e) => {
                            setRegData(e.target.value, "message");
                            ValueValidation("message");
                          }}
                        ></textarea>
                        {validation.message && (
                          <small className="text-danger">
                            {validation.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <button type="submit" className="form-btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Us End */}
        {/* FAQ Start */}
        <div className="Frequently-Asked-Questions">
          <div className="row">
            <div className="col-lg-6 col-sm-12" data-aos="fade-right">
              <div className="left-img">
                <img src={Group6} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12" data-aos="fade-left">
              <div className="right-colapse">
                <div className="container">
                  <h1 data-aos="fade-down">
                    Frequently Asked
                    <br /> Questions
                  </h1>
                  <div className="accordionSpace">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            How can i add customers to the store?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              By using add customer tab you can able to create a
                              customer for your store. By providing all the
                              general information.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            How can I able to print invoices?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              We recommend thermal printers to print the
                              invoice. While purchasing the printer size of the
                              bill should be taken care and that can either
                              connect with UBS or bluetooth. For your
                              convenience bluetooth devices are preferred while
                              connection with mobile app In our web store we
                              have multiple variates of thermal printers you can
                              also buy form there.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            How can I activate the subscription?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              You can navigate to the subscription page there
                              you will get all the details regarding your
                              current/previos subscription and you can able to
                              see a subscription form from where you can
                              activate the subscription.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            How can contact for an demo?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Contact from the contactUs page and we will
                              provide a demo and onboard you as a merchant.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            How Weegetz PoS work?
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              IBill is an all in one billing software. Here
                              users can create invoices by adding products and
                              customers on their own and can also manage the
                              inventory. With a simple user interface.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSix">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                          >
                            How can I purchase Weeget and integrate it with my
                            business?
                          </button>
                        </h2>
                        <div
                          id="collapseSix"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingSix"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              For Mobile: Download the Weegetz mobile
                              application form the PlayStore for android or
                              AppStore for ios For Desktop: Navigate to the
                              Weegetz application through the browser
                              <br />
                              1. Once downloaded, you can purchase the
                              subscription plan according to your store needs
                              and just follow the on-board process.
                              <br />
                              2. After onboarding successfully then you can use
                              Weegetz application
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSeven">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                          >
                            How can I add products to the store?
                          </button>
                        </h2>
                        <div
                          id="collapseSeven"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingSeven"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              The products can be added by using two ways
                              <br />
                              1. CSV file for bulk uploading
                              <br />
                              2. By adding products manually to the store.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEight">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEight"
                            aria-expanded="false"
                            aria-controls="collapseEight"
                          >
                            How can I add multiple products at the same time?
                          </button>
                        </h2>
                        <div
                          id="collapseEight"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingEight"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Download sample CSV file from the add product
                              section and fill the CSV with the appropriate
                              value and then upload the CSV file to the
                              application then it will automatically add the
                              products to the store.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ End */}
      </div>
      <Footer />
      <CustomModal
        open={successModal}
        onClickOutside={() => {
          setSuccessModal(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="modalHeader succ-failure">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setSuccessModal(false);
                }}
              >
                <i className="fa-solid fa-circle-xmark"></i>
              </span>

              <h6 className="text-center font-weight-strong my-2">
                <img
                  style={{
                    width: "50%",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Homepagelogo}
                  alt=""
                />

                {/* <span style={{fontSize:'65px',textShadow: 'rgb(171 167 167 / 44%) 0px 3px 6px'}}><i className="fa-solid fa-circle-xmark"></i></span> */}
              </h6>
            </div>

            <div className="container">
              <div className="row d-flex align-items-center justify-content-center thankyousubmit">
                <div className="col-lg-8 py-4">
                  <h3 className="font-weight-strong text-center">
                    Your details has been successfully submitted.
                    <br /> Thank you!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

export default Home;
