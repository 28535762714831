import './App.css';
import './responsive.css';
import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Homepagelogo from "./images/Group 18.png"

export default function Navigation() {

  const links = ['Home', 'Features', 'AppView', 'Pricing'];
  const [active, setActive] = useState('Home');
  const [active2, setActive2] = useState(null);
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="Navigationbar">
      {/* Offer-Timer-Start */}
      <div className='offer-timer fade-bottom'>
        <p>50% off Weegetz for 3 months</p>
        {/* Timer */}
        <div id="clockdiv">
          <div class="containered12">

            <div id="countdown">
              <ul>
                <li className='borderright'><span id="days"></span>Days</li>
                <li className='borderright'><span id="hours"></span>Hours</li>
                <li className='borderright'><span id="minutes"></span>Mins</li>
                <li><span id="seconds"></span>Secs</li>
              </ul>
            </div>

          </div>
          {/* <div className="border-right">
            <span className="days"></span>
            <div className="smalltext">Days</div>
          </div>
          <div className="border-right">
            <span className="hours"></span>
            <div className="smalltext">Hours</div>
          </div>
          <div className="border-right">
            <span className="minutes"></span>
            <div className="smalltext">Mins</div>
          </div>
          <div>
            <span className="seconds"></span>
            <div className="smalltext">Secs</div>
          </div> */}
        </div>
        {/* <div class="containered12">

          <div id="countdown">
            <ul>
              <li><span id="days"></span>Days</li>
              <li><span id="hours"></span>Hours</li>
              <li><span id="minutes"></span>Mins</li>
              <li><span id="seconds"></span>Secs</li>
            </ul>
          </div>

        </div> */}
        {/* Timer End*/}
        <div>
          <button href=''>Save Now</button>
        </div>
      </div>
      {/* Offer-Timer-End */}
      {/* Navbar_Section-Start */}
      <div className='navigation reveal fade-bottom'>
        <div className='container'>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <a className="navbar-brand" href="/"><img src={Homepagelogo} alt="Homepagelogo" /></a>

              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav">
                  {links.map((link) => (
                    <li className="nav-item active">
                      <a className="nav-link"
                        href={`#${link}`}
                        class={`nav-link ${active == link && 'm-active'}`}
                        onClick={() => setActive(link)}
                      >{link}</a>
                    </li>
                  ))}
                </ul>
                <a className="nav-link" href={`#Contact`} class={`black-button  py-1 font-weight-bold ${active2 == "Contact" && 'active'}`}
                  onClick={() => setActive2("Contact")}><button>Contact Us</button>
                </a>

              </div>
            </div>
          </nav>
          {/* <nav className="navbar home-navbar navbar-expand-lg navbar-light">
            <a className="navbar-brand" href="/"><img src={Homepagelogo} alt="Homepagelogo" /></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav">
                {links.map((link) => (
                  <li className="nav-item active">
                    <a className="nav-link"
                      href={`#${link}`}
                      className={`nav-link ${active == link && 'm-active'}`}
                      onClick={() => setActive(link)}
                    >{link}</a>
                  </li>
                ))}
              </ul>
              <a className="nav-link" href={`#Contact`} className={`black-button  py-1 font-weight-bold ${active2 == "Contact" && 'active'}`}
                onClick={() => setActive2("Contact")}><button>Contact Us</button>
              </a>
            </div>
          </nav> */}
        </div>
      </div>
      {/* Navbar-Section-End */}
    </div>
  );
}

